import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

import SEO from '../components/seo'
import HeroForm from '../components/heroForm'
import Box from '../components/box'
import Footer from '../components/footer'
import TabPicker from '../components/tabPicker'
import SectionsWithIcons from '../components/sectionsWithIcons'
import HeartbeatSignupForm from '../components/heartbeatSignupForm'
import HeaderText from '../components/headerText'
import SubText from '../components/subText'
import Nav from '../components/nav'
import checkUtmParams from '../utils/checkUtmParams'

import ProductEditor from '../icons/productEditor'
import ProductIntegrations from '../icons/productIntegrations'
import ProductAck from '../icons/productsAck'
import ProductTemplates from '../icons/productTemplates'
import ProductProjects from '../icons/productProjects'
import ProductTeams from '../icons/productTeams'
import ProductTopics from '../icons/productTopics'
import PulseEncrypted from '../icons/pulseEncrypted'
import useHotjarScript from '../utils/hooks/useHotjarScript'

const ProductPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "effortless-experience.png" }) {
        childImageSharp {
          fixed(width: 370, height: 700, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      pulseStreams: file(relativePath: { eq: "pulse-streams.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pulseNews: file(relativePath: { eq: "pulse-news.png" }) {
        childImageSharp {
          fixed(width: 527, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      productAck: file(relativePath: { eq: "product-ack.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productEditor: file(relativePath: { eq: "product-editor.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productIntegration: file(
        relativePath: { eq: "product-integration.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      productTemplates: file(relativePath: { eq: "product-templates.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    checkUtmParams()
  }, [])

  useHotjarScript()

  return (
    <>
      <SEO
        title="Product - Engineered to get everyone on the same page"
        description="Pulse is a stripped back communication tool to help you and your remote workforce stay always in sync and get on the same page – no matter where & where."
      />
      <Nav />
      <HeroContainer>
        <HeroForm
          headerText={
            <>
              Lightweight product. <br /> Effortless experience.
            </>
          }
          subText="No bloating, no gimmicks, just all the tools you need to communicate effortlessly with your remote workforce. Pulse is a stripped-back, pro-end workhorse engineered to get everyone on the same page, all the time."
        />
        <Box width="37.1875rem" className="hero-img-container">
          <Img fixed={data.heroImage.childImageSharp.fixed} fadeIn={false} />
        </Box>
      </HeroContainer>
      <Box
        width="43.75rem"
        maxWidth="97%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        margin="auto"
        marginTop="7rem"
        marginBottom="3.75rem"
      >
        <HeaderText as="h3">
          Pulses. <br />
          Anything that matters.
        </HeaderText>
        <SubText>
          From global announcements and project proposals to feedback on
          processes. From welcoming new employees to discussions around
          long-term endeavors. Everything you need your team to know is always
          one pulse away.
        </SubText>
      </Box>
      <Box width="90%" margin="auto" maxWidth="1200px" paddingBottom="8.125rem">
        <TabPicker
          options={[
            {
              icon: <ProductEditor />,
              title: 'Flawless Editor',
              description:
                'Markdown. Full ownership. Total focus. For a seamless experience as both a reader and a writer. Designed to help you focus on what you want to say.',
              component: (
                <Box width="700px" height="auto">
                  <Img
                    fluid={data.productEditor.childImageSharp.fluid}
                    fadeIn={false}
                  />
                </Box>
              ),
            },
            {
              icon: <ProductTemplates />,
              title: 'Templates',
              description:
                'Announce internal product releases. Kick off projects. Share proposals. Declare decisions. Give updates on metrics. Everything you need is just one click away from you so that you can be fast and brilliant at internal updates.',
              component: (
                <Box width="700px" height="auto">
                  <Img
                    fluid={data.productTemplates.childImageSharp.fluid}
                    fadeIn={false}
                  />
                </Box>
              ),
            },
            {
              icon: <ProductIntegrations />,
              title: 'Integrations? Elementary',
              description:
                'Loom videos, YouTube videos, Zoom videos... Lots and lots of videos. Plus slide decks, Figma documents, Airtables, and many more to come.',
              component: (
                <Box width="700px" height="auto">
                  <Img
                    fluid={data.productIntegration.childImageSharp.fluid}
                    fadeIn={false}
                  />
                </Box>
              ),
            },
            {
              icon: <ProductAck />,
              title: 'Cut Through the Clutter',
              description:
                'For mission-critical information enable Must Read. Gain more visibility and extra space across everyone’s feed, and ensure your team members will acknowledge your words after reading the message.',
              component: (
                <Box width="700px" height="auto">
                  <Img
                    fluid={data.productAck.childImageSharp.fluid}
                    fadeIn={false}
                  />
                </Box>
              ),
            },
          ]}
        />
      </Box>
      <Box
        height="31.25rem"
        display="flex"
        width="92.5%"
        margin="auto"
        marginBottom="7.5rem"
        maxWidth="1200px"
      >
        <Box backgroundColor="black" height="100%" display="flex">
          <OneFeed>
            <HeaderText style={{ color: '#ffffff', textAlign: 'left' }} as="h3">
              One feed. <br />
              One company firehose.
            </HeaderText>
            <SubText style={{ color: '#ffffff', textAlign: 'left' }}>
              One single unified platform for you and your team to stay ahead of
              the curve and oversee what's happening. The feed has been
              engineered to provide consistently high Signal/Noise ratio so that
              it’s never polluted with irrelevant things.
            </SubText>
          </OneFeed>
        </Box>
        <PulseNewsImage>
          <Img fixed={data.pulseNews.childImageSharp.fixed} fadeIn={false} />
        </PulseNewsImage>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
        width="43.75rem"
        maxWidth="97%"
        margin="auto"
      >
        <HeaderText as="h3">
          streams. <br />
          for teams, topics or projects.
        </HeaderText>
        <SubText as="p">
          Streams provide spaces to keep teams informed and aligned. When you
          send a pulse, just choose a stream – for example, @product-updates –
          and everyone who’s following it will read what you have to say.
        </SubText>
        <Box width="100%">
          <Img fluid={data.pulseStreams.childImageSharp.fluid} fadeIn={false} />
        </Box>
      </Box>
      <Box
        display="flex"
        width="100%"
        maxWidth="1200px"
        margin="auto"
        marginBottom="8.125rem"
      >
        <SectionsWithIcons
          sections={[
            {
              icon: <ProductTeams />,
              header: 'Streams for Teams',
              description:
                'Streamline focused communication to Teams or Department Units and ensure people are more attuned and responsive to internal changes.',
            },
            {
              icon: <ProductTopics />,
              header: 'Streams for Topics',
              description:
                'Improve cross-functional comms across topics, so that different teams are up to date on horizontal key items and areas of shared interest.',
            },
            {
              icon: <ProductProjects />,
              header: 'Streams for Projects',
              description:
                'Keep communication about internal projects open and accessible, so that everyone receives full visibility and stays informed – no matter where.',
            },
          ]}
        />
      </Box>
      <Privacy>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          flex={1}
        >
          <HeaderText style={{ color: '#ffffff' }} as="h3">
            Privacy
          </HeaderText>
          <p style={{ color: '#ffffff' }}>
            <Link to="/privacy/">Learn more about privacy</Link> ->
          </p>
        </Box>
        <Box className="pulse-encrypted">
          <PulseEncrypted />
        </Box>
      </Privacy>
      <HeartbeatSignupForm />
      <Footer />
    </>
  )
}

const HeroContainer = styled.div`
  display: flex;
  max-width: 75rem;
  min-height: 100%;
  height: 43.75rem;
  margin: auto;
  overflow: hidden;

  @media ${props => props.theme.device.laptopL} {
    max-width: 75rem;
  }
`

const OneFeed = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
  width: 90%;

  @media ${props => props.theme.device.tablet} {
    width: 75%;
  }
`

const Privacy = styled.div`
  background-color: black;
  width: 92.5%;
  max-width: 75rem;
  margin: auto;
  display: flex;
  height: auto;
  padding: 3rem 0;
  margin-bottom: 8.75rem;
  margin-top: 8.125rem;
  flex-direction: column;

  .pulse-encrypted {
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }

  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
    height: 25rem;
    padding: 0;

    .pulse-encrypted {
      margin: auto;
      flex: 1;
    }
  }
`

const PulseNewsImage = styled.div`
  display: none;

  @media ${props => props.theme.device.tablet} {
    min-width: 527px;
    min-height: 500px;
    overflow-y: hidden;
    display: block;
  }
`

export default ProductPage
