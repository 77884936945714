import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <path
        fill="#fff"
        d="M13.83 24.564l4.71-4.71-4.71-4.645-4.708 4.646 4.709 4.709zm6.046 5.982l4.646-4.646-4.646-4.71-4.709 4.71 4.71 4.646zm0-12.028l4.646-4.645-4.646-4.71-4.709 4.71 4.71 4.645zm5.982 6.046l4.645-4.71-4.645-4.645-4.646 4.646 4.646 4.709zm-12.027-2.156l-2.538-2.553 2.538-2.538 2.545 2.538-2.545 2.553zm12.027 0l-2.546-2.553 2.546-2.538 2.537 2.538-2.537 2.553zm-5.982-6.046l-2.537-2.49 2.537-2.553 2.49 2.554-2.49 2.49zm0 12.028L17.34 25.9l2.537-2.537 2.49 2.537-2.49 2.49z"
      ></path>
    </svg>
  )
}

export default Icon
