import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <mask
        id="mask0"
        width="40"
        height="40"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="20" cy="20" r="20" fill="#C4C4C4"></circle>
      </mask>
      <g fill="#fff" fillRule="evenodd" clipRule="evenodd" mask="url(#mask0)">
        <path d="M15.95 17.279l4.9 2.8v6.68l4.324 2.993-.449-.168-4.5-2-4.8 2.854-4.894-2.796-.531-.328V20.08l5.225-2.8v-6.195l.725-.15v6.345zm-4.9 9.45l3.85 2.2v-5.77l-3.85-1.926v5.496zm.587-6.376l3.788 1.894 3.788-1.894-3.788-2.165-3.788 2.165zm8.163.88l-3.85 1.925v5.77l3.85-2.2v-5.495z"></path>
        <path d="M20.95 8.279l4.9 2.8v6.68l4.324 2.993.051-.168-4.81-2.855-4.99 2.855-5.03-2.825-4.67 2.825v-.655L15 17.736V11.08l4.9-2.8.471-.279.579.279zm-4.9 9.45l3.85 2.2v-5.771l-3.85-1.925v5.496zm.587-6.376l3.788 1.894 3.788-1.894-3.788-2.165-3.788 2.165zm8.163.88l-3.85 1.925v5.77l3.85-2.2v-5.495z"></path>
        <path d="M25.425 17.584l5.425 2.495V27.306l-.56.352.008-.004-4.873 2.784-5.2-3.354-5 3-.22-.35L20 26.735V20.08l4.9-2.8v-6.345l.825.15-.3 6.5zm-4.375 9.145l3.85 2.2v-5.77l-3.85-1.926v5.496zm.587-6.376l3.788 1.894 3.788-1.894-3.788-2.154-3.788 2.154zm8.163.88l-3.85 1.925v5.77l3.85-2.2v-5.495z"></path>
      </g>
    </svg>
  )
}

export default Icon
