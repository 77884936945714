import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <path stroke="#000" d="M10.5 15v9"></path>
      <circle
        cx="20"
        cy="20"
        r="13.25"
        fill="#000"
        stroke="#fff"
        strokeWidth="1.5"
      ></circle>
      <circle
        cx="20"
        cy="20"
        r="7.25"
        fill="#000"
        stroke="#fff"
        strokeWidth="1.5"
      ></circle>
      <circle cx="20" cy="20" r="2" fill="#fff"></circle>
      <path
        fill="#fff"
        stroke="#fff"
        d="M13 9.5L20 20l3-12.5c-3-.5-2.152-.975-5-.5-3 .5-1.5-.5-5 2.5z"
      ></path>
    </svg>
  )
}

export default Icon
