import React from 'react'

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="20" fill="#000"></circle>
      <path
        fill="#fff"
        d="M11.435 26l1.15-3.316h4.896L18.625 26h2.007l-4.51-12.531h-2.184L9.428 26h2.007zm1.701-4.907l1.848-5.348h.098l1.848 5.348h-3.794zm11.894 5.115c1.554 0 2.43-.79 2.778-1.493h.073V26h1.787v-6.241c0-2.735-2.154-3.28-3.647-3.28-1.7 0-3.267.685-3.88 2.399l1.72.391c.27-.667.955-1.31 2.185-1.31 1.18 0 1.786.619 1.786 1.683v.043c0 .667-.685.655-2.374.85-1.78.209-3.604.674-3.604 2.81 0 1.847 1.39 2.863 3.176 2.863zm.398-1.468c-1.034 0-1.78-.465-1.78-1.371 0-.98.868-1.328 1.927-1.469.593-.08 2-.238 2.264-.501v1.211c0 1.114-.888 2.13-2.411 2.13z"
      ></path>
    </svg>
  )
}

export default Icon
